import type {Maybe} from "@graphql-tools/utils";
import {useNormalizeString} from "~/composables/useNormalizeString";

export const useNormalizeSearch = (
  haystack?: Maybe<string> | string | undefined,
  needle?: Maybe<string> | string | undefined
): boolean => {
  if (!haystack || !needle) {
    return true
  }
  const re = new RegExp(`${useNormalizeString(needle)}`, "gi")
  return useNormalizeString(haystack)?.search(re) !== -1
}
