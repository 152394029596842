import type {Maybe} from "@graphql-tools/utils";

export const useNormalizeString = (str?: Maybe<string> | string | undefined): Maybe<string> | string | undefined => {
  if (!str) {
    return str
  }
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}
