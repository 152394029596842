import type ArticleLocal from "~/libs/interfaces/ArticleLocal";
import {useNormalizeSearch} from "~/composables/useNormalizeSearch"

export const useArticleFulltextSearch = (
  useAiArticles: boolean,
  article?: ArticleLocal,
  fulltext?: string,
): boolean => {
  if (!fulltext || fulltext.length === 0 || !article) {
    return true
  }

  // Article name
  if (article.title && useNormalizeSearch(article.title, fulltext)) {
    return true
  }

  // Category name
  let foundInCategoryName = false
  if (!useAiArticles || !article.aiTopicList?.edges?.length) {
    article.categoryList?.edges?.forEach((category) => {
      if (category?.node?.name && useNormalizeSearch(category?.node?.name, fulltext)) {
        foundInCategoryName = true
      }
    })
  } else {
    article.aiTopicList?.edges?.forEach((topic) => {
      if (topic?.node?.name && useNormalizeSearch(topic?.node?.name, fulltext)) {
        foundInCategoryName = true
      }
    })
  }
  if (foundInCategoryName) {
    return true
  }

  // Author name
  let foundInAuthorName = false
  if (!useAiArticles || !article.aiAuthorList?.edges?.length) {
    article.authorList?.edges?.forEach((author) => {
      if (author?.node?.fullname && useNormalizeSearch(author?.node?.fullname, fulltext)) {
        foundInAuthorName = true
      }
    })
  } else {
    article.aiAuthorList?.edges?.forEach((author) => {
      if (author?.node?.name && useNormalizeSearch(author?.node?.name, fulltext)) {
        foundInAuthorName = true
      }
    })
  }
  if (foundInAuthorName) {
    return true
  }

  // magazine name
  if (article.magazine?.name && useNormalizeSearch(article.magazine?.name, fulltext)) {
    return true
  }

  // article body
  if (article.bodyPlain && useNormalizeSearch(article.bodyPlain, fulltext)) {
    return true
  }

  return false

}
