import gql from 'graphql-tag'

export const GET_ARTICLE = gql`
  query article(
    $id: ID!
  ) {
    article(
      id: $id
    ) {
      id
      authorList {
        edges {
          node {
            id
            fullname,
            imageAsset {
              path
              urlWithProtocol
            }
          }
        }
      },
      title
      previewAsset {
        path
        urlWithProtocol
        author
        source
      },
      published,
      magazine {
        name
      }
      categoryList {
        edges {
          node {
            id
            name
          }
        }
      }
      body
      jsonBody
      articleLinkAssetList {
        edges {
          node {
            id,
            asset {
              title,
              caption,
              source,
              author,
              path
            },
            position
          }
        }
      }
      aiAuthorList {
        edges {
          node {
            id
            name
            imageAsset {
              urlWithProtocol
            }
          }
        }
      }
      aiTopicList {
        edges {
          node {
            id
            name
          }
        }
      }
      hasAiValues
    }
  }
`