import {GET_ARTICLES} from "~/graphql/articles"
import type {Article, ArticleEdge} from "~/graphql/__generated__/graphql"
import {useDate} from "~/composables/useDate"
import type ArticleLocal from "~/libs/interfaces/ArticleLocal"
import articleLocalFactory from "~/libs/factories/ArticleLocalFactory"
import type CategoryLocal from "~/libs/interfaces/CategoryLocal"
import {GET_ARTICLE} from "~/graphql/article"
import {PersistedStoreType, usePersistedStore} from "~/stores/usePersistedStore"
import type {Ref} from "vue";
import {useArticleFulltextSearch} from "~/composables/useArticleFulltextSearch"
import {useArticleMagazineIdSearch} from "~/composables/useArticleMagazineIdSearch"
import {useArticleCategoryIdSearch} from "~/composables/useArticleCategoryIdSearch"
import {useArticleAuthorIdSearch} from "~/composables/useArticleAuthorIdSearch"
import {useApiIdConverter} from "~/composables/useApiIdConverter"
import type AiTopicLocal from "~/libs/interfaces/AiTopicLocal";

const logger = useFrontendLogger()
export const useArticlesStore = defineStore('articles', {
  state: () => {
    const state: {
      isLoading: Ref<boolean>
      isRelatedLoading: Ref<boolean>
      articles: ArticleLocal[]
      relatedArticles: ArticleLocal[]
      article: Ref<ArticleLocal | null>
    } = {
      isLoading: ref(false),
      isRelatedLoading: ref(false),
      articles: reactive([]),
      relatedArticles: reactive([]),
      article: ref(null),
    }
    return state
  },
  getters: {
    getIsLoading(state): boolean {
      return state.isLoading
    },
    getIsFilterActive(): boolean {
      const persistedStore = usePersistedStore()
      if (persistedStore.getIsFeedFulltextActive) {
        return true
      }
      if (persistedStore.feedSelectedCategories?.length > 0) {
        return true
      }
      return false
    },
    getShowMainArticle(): boolean {
      const config = useRuntimeConfig()
      return config.public.showFeedMainArticle && !this.getIsFilterActive
    },
    getFeedArticlesOffset(): number {
      return this.getShowMainArticle ? 1 : 0
    }
  },
  actions: {
    /**
     * Get categories with filtered articles - for category list
     * @param articleOffset
     */
    getFilteredCategories(articleOffset: number = 0): CategoryLocal[] | AiTopicLocal[] {
      // console.log(new Date().getMilliseconds(), 'getFilteredCategories START')
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()

      const persistedStore = usePersistedStore()
      const myCategoriesStore = useMyCategoriesStore()

      if (useAiArticles) {
        const topics: AiTopicLocal[] = []
        this.getFilteredArticles().forEach((article) => {
          if (article.aiTopicList && article.aiTopicList.edges) {
            article.aiTopicList.edges.forEach((topic) => {
              if (topic?.node?.id &&
                topic?.node?.name) {
                const existingTopic = topics.find((fTopic) => {
                  return fTopic.id === topic?.node?.id
                })
                if (!existingTopic) {
                  let isSelected = true
                  let hasArticles = false
                  if (persistedStore.feedSelectedCategories.length > 0 && !persistedStore.feedSelectedCategories.includes(topic?.node?.id)) {
                    isSelected = false
                  }
                  if (
                    myCategoriesStore.storedIds &&
                    myCategoriesStore.storedIds.length > 0 &&
                    !myCategoriesStore.storedIds.includes(topic?.node?.id)
                  ) {
                    isSelected = false
                  }
                  if (isSelected) {
                    hasArticles = this.hasCategoryArticles(topic?.node?.id, articleOffset)
                  }
                  topics.push({
                    ...(topic.node),
                    isVisible: isSelected && hasArticles
                  })
                }
              }
            })
          }
        })
        // console.log(new Date().getMilliseconds(), 'getFilteredCategories END')
        return topics
      }

      const categories: CategoryLocal[] = []
      this.getFilteredArticles().forEach((article) => {
        if (article.categoryList && article.categoryList.edges) {
          article.categoryList.edges.forEach((category) => {
            if (category?.node?.id &&
              category?.node?.name) {
              const existingCategory = categories.find((fCategory) => {
                return fCategory.id === category?.node?.id
              })
              if (!existingCategory) {
                let isSelected = true
                let hasArticles = false
                if (persistedStore.feedSelectedCategories.length > 0 && !persistedStore.feedSelectedCategories.includes(category?.node?.id)) {
                  isSelected = false
                }
                if (
                  myCategoriesStore.storedIds &&
                  myCategoriesStore.storedIds.length > 0 &&
                  !myCategoriesStore.storedIds.includes(category?.node?.id)
                ) {
                  isSelected = false
                }
                if (isSelected) {
                  hasArticles = this.hasCategoryArticles(category?.node?.id, articleOffset)
                }
                categories.push({
                  ...(category.node),
                  isVisible: isSelected && hasArticles
                })
              }
            }
          })
        }
      })
      // console.log(new Date().getMilliseconds(), 'getFilteredCategories END')
      return categories
    },
    /**
     * Get all categories
     */
    getCategories(): CategoryLocal[] | AiTopicLocal[] {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()

      if (useAiArticles) {
        const topics: AiTopicLocal[] = []
        this.articles.forEach((article) => {
          if (article.aiTopicList && article.aiTopicList.edges) {
            article.aiTopicList.edges.forEach((topic) => {
              if (topic?.node?.id && topic?.node?.name) {
                const existingTopic = categories.find((fTopic) => {
                  return fTopic.id === topic?.node?.id
                })
                if (!existingTopic) {
                  topics.push({
                    ...(topic.node),
                    isVisible: true
                  })
                }
              }
            })
          }
        })
        return topics
      }

      const categories: CategoryLocal[] = []
      this.articles.forEach((article) => {
        if (article.categoryList && article.categoryList.edges) {
          article.categoryList.edges.forEach((category) => {
            if (category?.node?.id && category?.node?.name) {
              const existingCategory = categories.find((fCategory) => {
                return fCategory.id === category?.node?.id
              })
              if (!existingCategory) {
                categories.push({
                  ...(category.node),
                  isVisible: true
                })
              }
            }
          })
        }
      })
      return categories
    },
    /**
     * Get available categories for user preset (selected categories, magazines, authors) - for menu
     * @param removeNotVisible
     */
    getAvailableCategories(removeNotVisible = false): CategoryLocal[] | AiTopicLocal[] {
      const { public: {
        enableMagazineArticleFilter,
        enableCategoryArticleFilter,
        enableAuthorArticleFilter,
        useAiArticles
      }} = useRuntimeConfig()

      const myMediaStore = useMyMediaStore()
      const myCategoriesStore = useMyCategoriesStore()
      const myAuthorsStore = useMyAuthorsStore()

      const categories: CategoryLocal[] = []
      const topics: AiTopicLocal[] = []

      this.articles.forEach((article) => {
        let isArticleVisible = true
        // filter magazines
        if (enableMagazineArticleFilter && isArticleVisible) {
          isArticleVisible = useArticleMagazineIdSearch(article, myMediaStore.storedIds)
        }

        // filter authors
        if (enableAuthorArticleFilter && isArticleVisible) {
          isArticleVisible = useArticleAuthorIdSearch(useAiArticles, article, myAuthorsStore.storedIds)
        }

        if (!useAiArticles && (!removeNotVisible || isArticleVisible) && article.categoryList && article.categoryList.edges) {
          article.categoryList.edges.forEach((category) => {
            if (category?.node?.id && category?.node?.name) {
              const existingCategory = categories.find((fCategory) => {
                return fCategory.id === category?.node?.id
              })
              let isVisible = isArticleVisible

              // filter categories
              if (
                enableCategoryArticleFilter &&
                isVisible &&
                myCategoriesStore.storedIds &&
                myCategoriesStore.storedIds.length > 0 &&
                !myCategoriesStore.storedIds.includes(category?.node?.id)
              ) {
                isVisible = false
              }

              if (!existingCategory && (!removeNotVisible || isVisible)) {
                categories.push({
                  ...(category.node),
                  isVisible: true
                })
              }
            }
          })
        } else if (useAiArticles && (!removeNotVisible || isArticleVisible) && article.aiTopicList && article.aiTopicList.edges) {
          article.aiTopicList.edges.forEach((topic) => {
            if (topic?.node?.id && topic?.node?.name) {
              const existingTopic = topics.find((fTopic) => {
                return fTopic.id === topic?.node?.id
              })
              let isVisible = isArticleVisible

              // filter categories
              if (
                enableCategoryArticleFilter &&
                isVisible &&
                myCategoriesStore.storedIds &&
                myCategoriesStore.storedIds.length > 0 &&
                !myCategoriesStore.storedIds.includes(topic?.node?.id)
              ) {
                isVisible = false
              }

              if (!existingTopic && (!removeNotVisible || isVisible)) {
                topics.push({
                  ...(topic.node),
                  isVisible: true
                })
              }
            }
          })
        }
      })

      if (!useAiArticles) {
        categories.forEach((category) => {
          let isVisible = true
          if (myCategoriesStore.storedIds && myCategoriesStore.storedIds.length > 0 && !myCategoriesStore.storedIds.includes(category.id)) {
            isVisible = false
          }
          category.isVisible = isVisible
        })

        return categories
      } else {
        topics.forEach((topic) => {
          let isVisible = true
          if (myCategoriesStore.storedIds && myCategoriesStore.storedIds.length > 0 && !myCategoriesStore.storedIds.includes(topic.id)) {
            isVisible = false
          }
          topic.isVisible = isVisible
        })

        return topics
      }
    },
    /**
     * Check if category has some articles after filtering
     * @param categoryId
     * @param offset
     */
    hasCategoryArticles(categoryId: string, offset: number = 0): boolean {
      const articles = this.getArticlesForCategory(categoryId, offset).filter((article) => {
        return article.isVisible
      })
      return articles.length > 0
    },
    /**
     * Get articles with applied filters and presets
     * @param offset
     */
    getFilteredArticles(offset: number = 0): ArticleLocal[] {
      const { public: {
        enableMagazineArticleFilter,
        enableCategoryArticleFilter,
        enableAuthorArticleFilter,
        useAiArticles
      }} = useRuntimeConfig()

      const myMediaStore = useMyMediaStore()
      const myCategoriesStore = useMyCategoriesStore()
      const myAuthorsStore = useMyAuthorsStore()
      const persistedStore = usePersistedStore()
      persistedStore.cleanUpSelectedCategories(PersistedStoreType.FEED, this.getAvailableCategories(true))

      let visibleCount = 0
      const articles = this.articles.map((article) => {
        let isVisible = true

        // fulltext filter
        if (isVisible) {
          isVisible = useArticleFulltextSearch(useAiArticles, article, persistedStore.feedFulltext)
        }

        // User preference - filter selected media
        if (enableMagazineArticleFilter && isVisible) {
          isVisible = useArticleMagazineIdSearch(article, myMediaStore.storedIds)
        }

        // User preference - filter selected category
        if (enableCategoryArticleFilter && isVisible) {
          isVisible = useArticleCategoryIdSearch(useAiArticles, article, myCategoriesStore.storedIds)
        }

        // User preference - filter selected author
        if (enableAuthorArticleFilter && isVisible) {
          isVisible = useArticleAuthorIdSearch(useAiArticles, article, myAuthorsStore.storedIds)
        }

        // Quick filter - filter categories
        if (isVisible) {
          isVisible = useArticleCategoryIdSearch(useAiArticles, article, persistedStore.feedSelectedCategories as string[])
        }

        // offset check
        if (isVisible && visibleCount < offset) {
          isVisible = false
          visibleCount = visibleCount + 1
        }

        return {
          ...article,
          isVisible
        }
      })
      // console.log('articles', articles.filter(a => a.isVisible))
      return articles
    },
    /**
     * Get count of getFilteredArticles
     * @param offset
     */
    getFilteredArticlesCount(offset: number = 0): number {
      const articles = this.getFilteredArticles(offset).filter(article => article.isVisible)
      return articles.length
    },
    /**
     * Get article by articleId
     * @param id
     */
    getArticle(id: string): ArticleLocal | undefined {
      const article = this.articles.find((article) => {
        return article.id.indexOf(id) !== -1
      })
      return article
    },
    /**
     * Get category by categoryId
     * @param id
     */
    getCategory(id: string): CategoryLocal | AiTopicLocal | undefined {
      return this.getFilteredCategories().find((category) => {
        return category.id.indexOf(id) !== -1
      })
    },
    /**
     * Get main article for feed
     */
    getMainArticle(): ArticleLocal {
      const articles = this.getFilteredArticles().filter((article) => {
        return article.isVisible
      })
      return articles[0]
    },
    /**
     * Get filtered articles for category by categoryId
     * @param categoryId
     * @param offset
     * @param limit
     */
    getArticlesForCategory(categoryId: string | undefined, offset: number = 0, limit: number = 3): ArticleLocal[] {
      const { public: {
          useAiArticles
      }} = useRuntimeConfig()
      // console.log(new Date().getMilliseconds(), 'getArticlesForCategory START')
      const articles: ArticleLocal[] = []
      let visibleCount = 0
      // TODO - performance!!!
      this.getFilteredArticles(offset).forEach((article) => {
        if (limit > 0 && visibleCount >= limit) {
          return
        }
        if (!useAiArticles && article.categoryList && article.categoryList.edges) {
          article.categoryList.edges.forEach((category) => {
            if (category?.node?.id && category?.node?.id === categoryId) {
              articles.push(article)
              if (article.isVisible) {
                visibleCount = visibleCount + 1
              }
            }
          })
        } else if (useAiArticles && article.aiTopicList && article.aiTopicList.edges) {
          article.aiTopicList.edges.forEach((topic) => {
            if (topic?.node?.id && topic?.node?.id === categoryId) {
              articles.push(article)
              if (article.isVisible) {
                visibleCount = visibleCount + 1
              }
            }
          })
        }
      })
      // console.log(new Date().getMilliseconds(), 'getArticlesForCategory END')
      return articles
    },
    /**
     * Get articles count by categoryId
     * @param categoryId
     * @param offset
     */
    getArticlesForCategoryCount(categoryId: string | undefined, offset: number = 0): number {
      const articles = this.getArticlesForCategory(categoryId, offset, 0)
      return articles.filter(article => article.isVisible).length
    },
    /**
     * Fetch articles from server
     * @param limit
     */
    async fetchArticles(limit: number = 100): Promise<void> {
      const { public: {
        enableMagazineArticleFilter,
        enableCategoryArticleFilter,
        enableAuthorArticleFilter,
        showOnlyPremiumContent,
        useAiArticles
      }} = useRuntimeConfig()

      const persistedStore = usePersistedStore()
      if (this.articles.length > 1 && !persistedStore.needSync(PersistedStoreType.FEED)) {
        return
      }
      const myMediaStore = useMyMediaStore()
      const myCategoriesStore = useMyCategoriesStore()
      const myAuthorsStore = useMyAuthorsStore()

      const apiIdToUuid = useApiIdConverter().apiIdToUuid

      this.isLoading = true
      const startOfTheDay = useDate().todayString()
      const filter = {
        first: limit,
        isDeleted: false,
        isCompleted: true,
        isPreview: false,
        ...(showOnlyPremiumContent && {
          premium: true
        }),
        published: [{
          after: startOfTheDay,
          before: new Date().toISOString()
        }],
        // custom filters
        ...(enableMagazineArticleFilter && myMediaStore.storedIds && myMediaStore.storedIds.length > 0 && {
          magazineList: myMediaStore.storedIds.map(apiIdToUuid)
        }),
        ...(!useAiArticles && enableCategoryArticleFilter && myCategoriesStore.storedIds && myCategoriesStore.storedIds.length > 0 && {
          categoryListList: myCategoriesStore.storedIds.map(apiIdToUuid)
        }),
        ...(!useAiArticles && enableAuthorArticleFilter && myAuthorsStore.storedIds && myAuthorsStore.storedIds.length > 0 && {
          authorListList: myAuthorsStore.storedIds.map(apiIdToUuid)
        }),
        // AI filters
        ...(useAiArticles && enableCategoryArticleFilter && myCategoriesStore.storedIds && myCategoriesStore.storedIds.length > 0 && {
          aiTopicListList: myCategoriesStore.storedIds.map(apiIdToUuid)
        }),
        ...(useAiArticles && enableAuthorArticleFilter && myAuthorsStore.storedIds && myAuthorsStore.storedIds.length > 0 && {
          aiAuthorListList: myAuthorsStore.storedIds.map(apiIdToUuid)
        }),
        ...(useAiArticles && {
          hasAiValues: true
        }),

      }

      console.log('filter', filter)

      if (import.meta.server) {
        try {
          const result = await useAsyncQuery(GET_ARTICLES, filter)
          if (result.error?.value) {
            logger?.error('useArticlesStore:fetchArticles:server - error', {errorDetail: result.error?.value})
          }

          //@ts-ignore
          const edges = result.data?.value?.articles?.edges || []
          const newArticles = edges.map((item: ArticleEdge) => item.node)
          this.addArticles(newArticles)
        } catch (e) {
          logger?.error('useArticlesStore:fetchArticles:server - exception', {errorDetail: e})
        }
        this.isLoading = false
      } else {
        try {
          const {onResult, onError} = useQuery(GET_ARTICLES, filter)
          onResult((result) => {
            this.isLoading = result.loading
            if (!result.loading) {
              const data = result.data?.articles?.edges || []
              if (data.length) {
                const newArticles = data.map((item: ArticleEdge) => item.node)
                this.addArticles(newArticles)
              }
            }
          })
          onError((error) => {
            this.isLoading = false
            logger?.error('useArticlesStore:fetchArticles:client - error', {errorDetail: error})
          })
        } catch (e) {
          this.isLoading = false
          logger?.error('useArticlesStore:fetchArticles:client - exception', {errorDetail: e})
        }
      }

      persistedStore.setLastSync(PersistedStoreType.FEED)
    },
    /**
     * Fetch related articles from server
     * @param limit
     */
    async fetchRelatedArticles(limit: number = 100): Promise<void> {
      const apiIdToUuid = useApiIdConverter().apiIdToUuid
      if (!this.article || !this.article?.categoryList || !this.article?.categoryList?.edges) {
        return
      }

      let categoryIds: string[] = this.article?.categoryList?.edges?.map((category) => {
        return category?.node?.id
      }).filter((category) => {
        return category !== undefined
      }) as string[]

      if (!categoryIds || categoryIds.length === 0) {
        return
      }

      this.isRelatedLoading = true
      const startOfTheDay = useDate().todayString()

      // very slow, so for now we weill filter articles locally
      // const filter = {
      //   first: limit,
      //   isDeleted: false,
      //   isCompleted: true,
      //   isPreview: false,
      //   published: [{
      //     after: startOfTheDay,
      //     before: new Date().toISOString()
      //   }],
      //   categoryListList: categoryIds.map(apiIdToUuid)
      // }

      const filter = {
        first: 100,
        isDeleted: false,
        isCompleted: true,
        isPreview: false,
        published: [{
          after: startOfTheDay,
          before: new Date().toISOString()
        }],
        // categoryListList: categoryIds.map(apiIdToUuid) // very slow, so for now we weill filter articles locally
      }

      if (import.meta.server) {
        try {
          const result = await useAsyncQuery(GET_ARTICLES, filter)
          if (result.error?.value) {
            logger?.error('useArticlesStore:fetchRelatedArticles:server - error', {errorDetail: result.error?.value})
          }

          //@ts-ignore
          const edges = result.data?.value?.articles?.edges || []
          const newArticles = edges.map((item: ArticleEdge) => item.node)
          this.addRelatedArticles(newArticles, categoryIds)
        } catch (e) {
          logger?.error('useArticlesStore:fetchRelatedArticles:server - exception', {errorDetail: e})
        }
        this.isRelatedLoading = false
      } else {
        try {
          const {onResult, onError} = useQuery(GET_ARTICLES, filter)
          onResult((result) => {
            this.isRelatedLoading = result.loading
            if (!result.loading) {
              const data = result.data?.articles?.edges || []
              if (data.length) {
                const newArticles = data.map((item: ArticleEdge) => item.node)
                this.addRelatedArticles(newArticles, categoryIds)
              }
            }
          })
          onError((error) => {
            this.isRelatedLoading = false
            logger?.error('useArticlesStore:fetchRelatedArticles:client - error', {errorDetail: error})
          })
        } catch (e) {
          this.isRelatedLoading = false
          logger?.error('useArticlesStore:fetchRelatedArticles:client - exception', {errorDetail: e})
        }
      }
    },
    /**
     * Fetch article detail by articleId
     * @param id
     */
    async fetchArticle(id: string): Promise<void> {
      if (this.article && this.article?.id === id) {
        return
      }

      this.isLoading = true
      this.article = null
      if (import.meta.server) {
        try {
          const result = await useAsyncQuery(GET_ARTICLE, {id})
          if (result.error?.value) {
            logger?.error('useArticlesStore:fetchArticle:server - error', {errorDetail: result.error?.value})
            showError({
              statusCode: 404,
              statusMessage: "Page Not Found"
            })
          }
          //@ts-ignore
          const article = result.data?.value?.article
          if (!article) {
            logger?.error('useArticlesStore:fetchArticle:server - article not found', {errorDetail: result.data})
            showError({
              statusCode: 404,
              statusMessage: "Page Not Found"
            })
          } else {
            // this.addArticles([article], true)
            this.setArticle(article)
          }
        } catch (e) {
          logger?.error('useArticlesStore:fetchArticle:server - exception', {errorDetail: e})
          showError({
            statusCode: 404,
            statusMessage: "Page Not Found"
          })
        }
        this.isLoading = false
      } else {
        try {
          const {onResult, onError} = useQuery(GET_ARTICLE, {id})
          onResult((result) => {
            this.isLoading = result.loading
            if (!result.loading) {
              const article = result.data?.article
              if (!article) {
                logger?.error('useArticlesStore:fetchArticle:server - article not found', {errorDetail: result.data})
                showError({
                  statusCode: 404,
                  statusMessage: "Page Not Found"
                })
              } else {
                // this.addArticles([article], true)
                this.setArticle(article)
              }
            }
          })
          onError((error) => {
            this.isLoading = false
            logger?.error('useArticlesStore:fetchArticle:client - error', {errorDetail: error})
            showError({
              statusCode: 404,
              statusMessage: "Page Not Found"
            })
          })
        } catch (e) {
          this.isLoading = false
          logger?.error('useArticlesStore:fetchArticle:client - exception', {errorDetail: e})
          showError({
            statusCode: 404,
            statusMessage: "Page Not Found"
          })
        }
      }
    },
    /**
     * Add articles to store - remove duplicities
     * @param newArticles
     * @param rewrite
     */
    addArticles(newArticles: Article[], rewrite: boolean = false): void {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()
      newArticles.forEach((newArticle) => {
        const articleLocal = articleLocalFactory.createArticleLocal(newArticle, useAiArticles)
        const foundIndex = this.articles.findIndex(article => article.id === newArticle.id)
        if (foundIndex >= 0 && rewrite) {
          this.articles.splice(foundIndex, 1)
        }
        if (foundIndex === -1 || rewrite) {
          this.articles.push(articleLocal)
        }
      })

      // sort articles
      this.articles.sort((a, b) => {
        if (a.publishedDate && b.publishedDate) {
          return a.publishedDate?.getTime() > b?.publishedDate?.getTime() ? -1 : 1
        }
        return 1
      })
    },

    /**
     * Add related articles to store - remove duplicities
     * @param newArticles
     * @param categoryIds
     * @param rewrite
     */
    addRelatedArticles(newArticles: Article[], categoryIds: string[], rewrite: boolean = false): void {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()
      newArticles.forEach((newArticle) => {
        if (this.article?.id !== newArticle.id) {
          const matchingCategory =  newArticle.categoryList?.edges?.find((category) => {
            return category?.node?.id && categoryIds.includes(category.node.id)
          })
          if (matchingCategory) {
            const articleLocal = articleLocalFactory.createArticleLocal(newArticle, useAiArticles)
            const foundIndex = this.relatedArticles.findIndex(article => article.id === newArticle.id)
            if (foundIndex >= 0 && rewrite) {
              this.relatedArticles.splice(foundIndex, 1)
            }
            if (foundIndex === -1 || rewrite) {
              this.relatedArticles.push(articleLocal)
            }
          }
        }
      })

      // sort articles
      this.relatedArticles.sort((a, b) => {
        if (a.publishedDate && b.publishedDate) {
          return a.publishedDate?.getTime() > b?.publishedDate?.getTime() ? -1 : 1
        }
        return 1
      })
    },

    setArticle(newArticle: Article): void {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()
      // remove related articles
      this.relatedArticles.splice(0, this.relatedArticles.length)
      this.article = articleLocalFactory.createArticleLocal(newArticle, useAiArticles)
      // console.log('this.article', this.article)
    }

  }
})
